export const Tools = [
    { name: "C Language", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-plain.svg" },
    { name: "C++ Language", link: "https://upload.wikimedia.org/wikipedia/commons/1/18/ISO_C%2B%2B_Logo.svg" },
    { name: "JavaScript", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" },
    { name: "Python", link: "https://s3.dualstack.us-east-2.amazonaws.com/pythondotorg-assets/media/community/logos/python-logo-only.png" },
    { name: "MySQl", link: "https://www.svgrepo.com/show/473731/mysql.svg" },
    { name: "React", link: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" },
    { name: "NodeJS", link: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/885px-Node.js_logo.svg.png?20170401104355" },
    { name: "Express", link: "https://cdn.icon-icons.com/icons2/2699/PNG/512/expressjs_logo_icon_169185.png"},
    { name: "Strapi", link: "https://www.svgrepo.com/show/354399/strapi-icon.svg"},
    { name: "Nextjs", link: "https://www.svgrepo.com/show/354113/nextjs-icon.svg"},
    { name: "HTML", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" },
    { name: "CSS", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg" },
    { name: "Sass", link: "https://cdn.worldvectorlogo.com/logos/sass-1.svg" },
    { name: "Bootstrap", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain.svg" },
    { name: "Tailwind CSS", link: "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg" },
    { name: "Git", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg" },
    { name: "Mongo DB", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg" },
    { name: "Postman", link: "https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg" },
  ]